<template>
  <div class="modal-signup">
    <mkp-image :lazy-src="url" class="img-signup" />

    <h1 class="modal-signup-title">{{ $t("forms.singup.title") }}</h1>

    <component
      :is="componentLoader"
      :props="option"
      @SingUp="modifStep($event)"
    />
  </div>
</template>

<script>
import MkpImage from "../../../simple/image/mkp-image";
import "./mkp-signup.scss";
import { getValidBaseUrl } from "../../../../utils/dataValidator.js";

export default {
  name: "MkpSingup",
  components: { MkpImage },
  data: () => {
    return {
      option: {},
      url: require("./../../../../assets/images/sign-up.svg"),
      step: "form",
      steps: {
        form: () => import("./steps/mkp-form-singup"),
        validation: () => import("./steps/mkp-singup-validation")
      }
    };
  },
  computed: {
    componentLoader() {
      return this.steps[this.step];
    }
  },
  methods: {
    modifStep(event) {
      this.$store
        .dispatch("sendRequestAccount", {
          _this: this,
          url: getValidBaseUrl() + process.env.VUE_APP_BASE_URL_API + "/users",
          body: {
            companies: [
              {
                label: event.enterprise
              }
            ],
            email: event.email,
            firstName: event.firstName,
            lastName: event.lastName,
            login: event.firstName + "." + event.lastName
          }
        })
        .then(() => {
          this.option = {
            email: event.email
          };
          //TODO: I18N faire
          this.$notify({
            group: "form",
            type: "notification",
            title: "Inscription",
            text: "Demande d'inscription réalisé"
          });
          this.step = "validation";
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style scoped></style>
